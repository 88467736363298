






























































































































import { Aluno, Estado } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { AlunoService } from "@/core/services/geral";
import {Component} from "vue-property-decorator";

@Component
export default class DetalharAluno extends PageBase{
  item = new Aluno();
  service = new AlunoService();

  estadoId:number = 0;
  estado:Estado = new Estado();

  mounted() {
    const alunoId: number = Number(this.$route.params.id);
    this.service.ObterPorId(alunoId,"Genero,Usuario.Indicador,Usuario.Gestor,Endereco.Municipio.Estado,Profissao,Empresa").then(
      res =>{
        this.item = res.data;
      },
      err =>{
        if (!err.response){
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        }
        else{
          this.$swal("Aviso",err.response.data,"error")
        } 
      }
    )
  }

}
